import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 40;
  .container {
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 55px;
    img {
      cursor: pointer;
    }
    .social-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 84px;
      margin-left: auto;
      margin-right: 41px;
    }

    .contato-header {
      margin-right: 50px;
      &.theme {
        h3 {
          font-style: normal;
          font-weight: normal;
          font-size: ${({ theme }) => theme.fonts.xss};
          line-height: 100%;
          color: #000 !important;
          transition: all 0.3s ease;
        }
      }
      h3 {
        font-style: normal;
        font-weight: normal;
        font-size: ${({ theme }) => theme.fonts.xss};
        line-height: 100%;
        color: ${(props) => props.theme.colors.contrast};
        transition: all 0.3s ease;
      }
    }

    .toogle {
      position: relative;
      width: 23px;
      height: 16px;
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-mask: url("/img/icone/toggle.svg");
        -webkit-mask-size: contain;
        background-color: ${(props) => props.theme.colors.tertiary};
      }
      cursor: pointer;
      img {
        cursor: pointer;
      }
    }
  }

  @media (max-width: 480px) {
    .container {
      margin-top: 43px;
      .container-logo-header {
        height: 52px;
      }
      .contato-header,
      .social-header {
        display: none;
      }

      .toogle {
        margin-left: auto;
      }
    }
  }
`;
